/* eslint-disable max-len */
import React from 'react';
import '../Home/Home.scss';
import { Container, Row, Col } from 'react-bootstrap';
import multisignalsLogo from '../../assets/img/logo/logo.png';
import AutomatedTrading from '../../assets/img/logo/multisignalsAi/AutomatedTrading.webp';
import BacktestingandStrategyOptimization from '../../assets/img/logo/multisignalsAi/BacktestingandStrategyOptimization.webp';
import PortfolioManagement from '../../assets/img/logo/multisignalsAi/PortfolioManagement.webp';
import riskManagement from '../../assets/img/logo/multisignalsAi/riskManagement.webp';

function About({ ...props }) {

  return (
    <div className="home-page">
      <Container fluid className="text-center text-light">
        <Row className="home-section" id="about">
          <Col>
            <img src={multisignalsLogo} alt="Logo" width={100} />
            <h2 className="section-title">Multisignals</h2>
            <p className="section-text">
              Multisignals is an advanced trading platform that enables users to trade cryptocurrencies automatically through sophisticated bot systems. Designed for both beginners and experts, it provides a suite of tools for informed trading decisions in the fast-paced world of crypto.
            </p>
          </Col>
        </Row>

        <Row className="home-section" id="features">
          <Col md={6} className="text-left">
            <h2 className="section-title">Key Features</h2>
            <p className="section-text"><strong>Automated Trading:</strong> Execute trades automatically based on market conditions and set parameters.</p>
            <p className="section-text"><strong>Portfolio Management:</strong> Track and rebalance your assets in real-time.</p>
          </Col>
          <Col md={6}>
            <img src={AutomatedTrading} alt="Key Features" className="section-image" />
          </Col>
        </Row>

        <Row className="home-section flex-row-reverse" id="risk">
          <Col md={6} className="text-left">
            <h2 className="section-title">Risk Management</h2>
            <p className="section-text"><strong>Stop-Loss and Take-Profit Orders:</strong> Protect investments by setting automatic sell points.</p>
            <p className="section-text"><strong>Customizable Alerts:</strong> Receive alerts for specific market conditions.</p>
          </Col>
          <Col md={6}>
            <img src={riskManagement} alt="Risk Management" className="section-image" />
          </Col>
        </Row>

        <Row className="home-section" id="analysis">
          <Col md={6} className="text-left">
            <h2 className="section-title">Technical Analysis</h2>
            <p className="section-text">
              Access advanced charting tools and indicators like RSI and MACD along with market news feeds.
            </p>
          </Col>
          <Col md={6}>
            <img src={PortfolioManagement} alt="Technical Analysis" className="section-image" />
          </Col>
        </Row>

        <Row className="home-section flex-row-reverse" id="backtesting">
          <Col md={6} className="text-left">
            <h2 className="section-title">Backtesting & Optimization</h2>
            <p className="section-text"><strong>Historical Testing:</strong> Evaluate strategies using historical data for better results.</p>
            <p className="section-text"><strong>Optimization Tools:</strong> Fine-tune strategies to maximize profitability.</p>
          </Col>
          <Col md={6}>
            <img src={BacktestingandStrategyOptimization} alt="Backtesting & Optimization" className="section-image" />
          </Col>
        </Row>

        <Row className="home-section" id="whyus">
          <Col>
            <img src={multisignalsLogo} alt="Logo" width={100} />
            <h2 className="section-title">Why Choose Multisignals?</h2>
            <p className="section-text">
              Multisignals empowers users with tools for automated trading, insightful analysis, and strategic growth in cryptocurrency.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
