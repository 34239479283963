import { takeEvery, all, call, put } from 'redux-saga/effects';

import * as types from './newsActionTypes';
import * as actions from './newsActions';
import * as endpoints from '../../services/endpoints';

function* getNewsSaga(action) {
  try {
    const { data } = yield call(endpoints.getNews, action.payload);
    yield put(actions.getNewsDataAction(data));
  } catch (e) {
    yield put(actions.getNewsErrorAction(e));
  }
}

function* watchGetNews() {
  yield takeEvery(types.GET_NEWS_REQUEST, getNewsSaga);
}


export function* newsSaga() {
  yield all([watchGetNews()]);
}
