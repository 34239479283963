import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import AddNewApi from './AddNewApi';
import { buyTokenModalAction } from '../../store/token/tokenActions';

function AddNewApiModal({ ...props }) {
  const { buyTokenModal, buyTokenModalRequest } = props;

  const handleClose = () => {
    buyTokenModalRequest(false);
  };

  return (
    <Modal show={buyTokenModal} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton>
          <Modal.Title>Add New API</Modal.Title>
        </Modal.Header>
      <Modal.Body >
      <AddNewApi handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
}
const mapStateToProps = (state) => {
  return {
    buyTokenModal: state.tokenReducer.buyTokenModal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    buyTokenModalRequest: (payload) => {
      dispatch(buyTokenModalAction(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewApiModal);
