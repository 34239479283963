import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import {
  AreaChart, Area,
  CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Label
} from "recharts";
import "./PnlHistoryChart.scss";
import { setLoadingFullAction } from '../../store/loading/loadingActions';
import { connect } from 'react-redux';

function PnlHistoryChart(props) {
  const {
    pnlHistoryData,
    setLoadingFull,
  } = props;

  const [loading, setLoading] = useState(true);
  const [totalPnl, setTotalPnl] = useState(0);
  const [totalPnlPercent, setTotalPnlPercent] = useState(0);

  console.log("pnlHistoryData", pnlHistoryData);

  useEffect(() => {
    if (!pnlHistoryData || !pnlHistoryData.pnl_list) {
      setLoading(true);
      setLoadingFull({ isLoading: true, loadingText: 'Loading User Data...' });
      setTimeout(() => {
        setLoadingFull({ isLoading: false, loadingText: '' });
      }, 10000);
    } else {
      setLoading(false);
      setLoadingFull({ isLoading: false, loadingText: '' });

      const totalPnlValue = pnlHistoryData.pnl_list.reduce(
        (acc, item) => acc + item.pnl, 0);
      const totalPnlPercentValue = pnlHistoryData.pnl_list.reduce(
        (acc, item) => acc + item.pnl_percent, 0);

      setTotalPnl(totalPnlValue);
      setTotalPnlPercent(totalPnlPercentValue);
    }
  }, [pnlHistoryData, setLoadingFull]);

  const gradientOffset = () => {
    if (!pnlHistoryData || !pnlHistoryData.pnl_list || pnlHistoryData.pnl_list.length === 0) {
      return 0;
    }

    const dataMax = Math.max(...pnlHistoryData.pnl_list.map((i) => i.pnl));
    const dataMin = Math.min(...pnlHistoryData.pnl_list.map((i) => i.pnl));

    if (dataMax <= 0) return 0;
    if (dataMin >= 0) return 1;
    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  if (loading) {
    return (
      <div className="pnl-history-chart mx-3 my-4">
        <Row>
          <Col sm={12} md={12}>
            <Card>
              <Card.Body>
                <Card.Title>Loading PNL Data...</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  if (!pnlHistoryData || !pnlHistoryData.pnl_list || pnlHistoryData.pnl_list.length === 0) {
    return (
      <div className="pnl-history-chart mx-3 my-4">
        <Row>
          <Col sm={12} md={12}>
            <Card>
              <Card.Body>
                <Card.Title>Hourly PNL (Last 24 Hours)</Card.Title>
                <div className="no-data-message">
                  Your account has no transactions in the last 24 hours.
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className="pnl-history-chart mx-3 my-4">
      <Row>
        <Col sm={12} md={12}>
          <Card>
            <Card.Body>
              <Card.Title>Hourly PNL (Last 24 Hours)</Card.Title>
              <ResponsiveContainer width="100%" height={360}>
                <AreaChart
                  data={pnlHistoryData?.pnl_list || []}
                  margin={{ top: 5, right: 30, left: 20, bottom: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="time"
                    tick={({ x, y, payload }) => {
                      const [date, time] = payload.value.split(' ');
                      return (
                        <g transform={`translate(${x},${y + 10})`}>
                          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                            <tspan x="0" dy="0">{date}</tspan>
                            <tspan x="0" dy="15">{time}</tspan>
                          </text>
                        </g>
                      );
                    }}
                  >
                    <Label
                      value="Time"
                      position="insideBottom"
                      offset={-30}
                      style={{ textAnchor: 'middle', fontWeight: 'bold', fill: '#555' }}
                    />
                  </XAxis>
                  <YAxis dataKey="pnl">
                    <Label
                      value="PNL"
                      angle={-90}
                      offset={-5}
                      position="insideLeft"
                      style={{ textAnchor: 'middle', fontWeight: 'bold', fill: '#555' }}
                    />
                  </YAxis>
                  <Tooltip />
                  <defs>
                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset={off} stopColor="green" stopOpacity={1} />
                      <stop offset={off} stopColor="red" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="pnl"
                    stroke="#000"
                    fill="url(#splitColor)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Card.Body>
            <Row className="general-pnl d-flex justify-content-end align-items-center">
              <Col className="d-flex flex-row align-items-center gap-1 mx-4 mb-2">
                <div>Today's PNL</div>
                <div style={{ color: totalPnl < 0 ? 'red' : 'green' }}>
                  {"$" + totalPnl.toString().split(".")[0] + "." +
                    (totalPnl.toString().split(".")[1] ?
                      totalPnl.toString().split(".")[1].slice(0, 2) : "00")}
                  {" (%" + totalPnlPercent.toString().split(".")[0] + "." +
                    (totalPnlPercent.toString().split(".")[1] ?
                      totalPnlPercent.toString().split(".")[1].slice(0, 2) : "00") + ")"}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    pnlHistoryData: state.botReducer.pnlHistoryData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PnlHistoryChart);
