import * as types from './newsActionTypes';

export const getNewsRequestAction = (payload) => {
  return {
    type: types.GET_NEWS_REQUEST,
    payload,
  };
};

export const getNewsDataAction = (payload) => {
  return {
    type: types.GET_NEWS_DATA,
    payload,
  };
};

export const getNewsErrorAction = (payload) => {
  return {
    type: types.GET_NEWS_ERROR,
    payload,
  };
};
