import { useEffect } from 'react';
import { connect } from 'react-redux';
import MercurXFooter from '../../components/MercurXFooter/MercurXFooter';
import MercurXNavbar from '../../components/MercurXNavbar/MercurXNavbar';
import './PublicLayout.scss';

function PublicLayout({ ...props }) {
  const { children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <MercurXNavbar {...props} isPublic={true} />
      <div className="public-layout">
        <div className="content d-flex flex-row justify-content-center">
          {children}
        </div>
        <MercurXFooter {...props} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(PublicLayout);
