import MainLayout from './layouts/MainLayout/MainLayout';
import PublicLayout from './layouts/PublicLayout/PublicLayout';

import Home from './pages/Home/Home';
import Launchpad from './pages/Launchpad/Launchpad';
import UserRoute from './routes/UserRoute';
import Login from './pages/Login/Login';
import SignUp from './pages/SignUp/SignUp';
import ProjectDetail from './pages/ProjectDetail/ProjectDetail';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ProjectForm from './pages/ProjectForm';
import Activation from './pages/Activation/Activation';

import { Route } from 'react-router-dom';
import Profile from './pages/Profile/Profile';
import Sales from './pages/Sales/Sales';
import Staking from './pages/Staking/Staking';
import Whitepaper from './pages/Whitepaper/Whitepaper';
import About from './pages/About/About';
import Wallet from './pages/Wallet/Wallet';
import Marketplace from './pages/Marketplace/Marketplace'
import BuyPackage from './pages/BuyPackage/BuyPackage';
import ApiSettings from './pages/ApiSettings/ApiSettings';
import BotManagement from './pages/BotManagement/BotManagement';
import BotDetail from './pages/BotDetail/BotDetail';
import BotManagementRegistered from './pages/BotManagementRegistered/BotManagementRegistered';
import UpdateBotManagement from './pages/UpdateBotManagement/UpdateBotManagement';
import MyBotHistory from './pages/MyBotHistory/MyBotHistory';
import UserHome from './pages/UserHome/UserHome';

const routes = {
  PublicLayout: {
    exact: true,
    name: 'Public',
    component: PublicLayout,
    children: [
      {
        path: '/login',
        exact: true,
        name: 'Login',
        component: Login,
        routeComponent: Route,
      },
      {
        path: '/ProjectAdd',
        exact: true,
        name: 'Project Form',
        component: ProjectForm,
        routeComponent: Route,
      },
      {
        path: '/forgotpassword',
        exact: true,
        name: 'Forgot Password',
        component: ForgotPassword,
        routeComponent: Route,
      },
      {
        path: '/reset-password',
        exact: true,
        name: 'Reset Password',
        component: ResetPassword,
        routeComponent: Route,
      },
      {
        path: '/activate_user',
        exact: true,
        name: 'Activation',
        component: Activation,
        routeComponent: Route,
      },
      {
        path: '/SignUp',
        exact: true,
        name: 'Sign Up',
        component: SignUp,
        routeComponent: Route,
      },
      {
        path: '/',
        exact: true,
        name: 'Empty Page',
        component: Home,
        routeComponent: Route,
      }
    ],
  },
  MainLayout: {
    exact: true,
    name: 'Main',
    component: MainLayout,
    children: [
      {
        path: '/home',
        name: 'UserHome',
        component: UserHome,
        routeComponent: UserRoute,
      },
      {
        path: '/about',
        name: 'Project Detail',
        component: About,
        routeComponent: Route,
      },
      {
        path: '/Launchpad',
        name: 'Launchpad',
        component: Launchpad,
        routeComponent: UserRoute,
      },
      {
        path: '/Profile',
        name: 'Profile',
        component: Profile,
        routeComponent: UserRoute,
      },
      {
        path: '/sales',
        name: 'Sales',
        component: Sales,
        routeComponent: UserRoute,
      },
      {
        path: '/staking',
        name: 'Staking',
        component: Staking,
        routeComponent: UserRoute,
      },
      {
        path: '/project/:id',
        name: 'Project Detail',
        component: ProjectDetail,
        routeComponent: UserRoute,
      },
    
      {
        path: '/wallet',
        name: 'Wallet',
        component: Wallet,
        routeComponent: UserRoute,
      },
      {
        path: '/marketplace',
        name: 'Marketplace',
        component: Marketplace,
        routeComponent: UserRoute,
      },
      {
        path: '/buy-package',
        name: 'BuyPackage',
        component: BuyPackage,
        routeComponent: UserRoute,
      },
      {
        path: '/general-settings/api-settings',
        name: 'ApiSettings',
        component: ApiSettings,
        routeComponent: UserRoute,
      },
      {
        path: '/general-settings/bot-management/:id',
        name: 'BotManagement',
        component: BotManagement,
        routeComponent: UserRoute,
      },
      {
        path: '/general-settings/update-bot-management/:id',
        name: 'UpdateBotManagement',
        component: UpdateBotManagement,
        routeComponent: UserRoute,
      },
      {
        path: '/general-settings/bot-management',
        name: 'BotManagementRegistered',
        component: BotManagementRegistered,
        routeComponent: UserRoute,
      },
      {
        path: '/bot-detail/:id',
        name: 'BotDetail',
        component: BotDetail,
        routeComponent: UserRoute,
      },
      {
        path: '/my-bot-history',
        name: 'MyBotHistory',
        component: MyBotHistory,
        routeComponent: UserRoute,
      },
      {
        path: '/whitepaper',
        name: 'Project Detail',
        component: Whitepaper,
        routeComponent: UserRoute,
      },
    ],
  },
};

export default routes;
