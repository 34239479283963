/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, InputGroup, Form, Container, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { buyTokenModalAction } from '../../store/token/tokenActions';
import { useParams, useLocation } from 'react-router-dom';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
import { mainColors } from '../../helpers/colors';
import Swal from 'sweetalert2';
import { registerRequest, registerUpdateRequest, unregisterRequest } from '../../store/bot/botActions';
import { getBotByIdRequestAction, getRegisteredBotRequestAction } from '../../store/bot/botActions';
import { useHistory } from 'react-router-dom';
function BotManagementDetailInputs({ setLoadingFull,
    unregisterRequest, registerRequest, registerUpdateRequest, isEdit, user, getBotByIdRequest,
    registeredBotData, getRegisteredBotRequest, botDataById }) {
    const { id } = useParams();
    const location = useLocation();
    const [valueToBeMultiplied, setValueToBeMultiplied] = useState(0);
    const [valueToBeMultipliedParts, setValueToBeMultipliedParts] = useState(1);
    const [rangeValue, setRangeValue] = useState(0);
    const [showUnregisterPopup, setShowUnregisterPopup] = useState(false);
    const [exchangeName, setExchangeName] = useState('');
    const history = useHistory();
    const bot_id = id;
    const item = location.state?.item;

    useEffect(() => {
        if (item) {
            const investment = parseFloat(item.investment) || 0;
            const portion = item.portion || 1;
            setValueToBeMultiplied(Math.round(investment));
            setValueToBeMultipliedParts(portion);
            setRangeValue(Math.round(investment));
        }
    }, [item]);

    useEffect(() => {
        const payload = { bot_id };
        getBotByIdRequest(payload);
    }, [bot_id, getBotByIdRequest]);

    function formatNumber(value) {
        if (typeof value !== 'number') {
            value = parseFloat(value);
        }
        return Math.round(value).toLocaleString('en-US').replace(/,/g, '.');
    }


    function parseNumber(value) {
        return parseFloat(value.replace(/\./g, '').replace(/,/g, '.')) || 0;
    }

    useEffect(() => {
        getRegisteredBotRequest();
    }, [getRegisteredBotRequest]);

    function handleValueChange(e) {
        const inputValue = e.target.value;
        const numericValue = parseNumber(inputValue);
        const maxBalance = parseNumber(calculateBalance());

        if (numericValue > maxBalance) {
            setValueToBeMultiplied(maxBalance);
            setRangeValue(maxBalance);
        } else {
            setValueToBeMultiplied(Math.round(numericValue));
            setRangeValue(Math.round(numericValue));
        }
    }

    function handleRangeChange(e) {
        const newValue = Math.round(parseFloat(e.target.value));
        setRangeValue(newValue);
        setValueToBeMultiplied(newValue);
    }

    function calculateBalance() {
        const formatBalance = user?.usdt_balance?.free;
        return Number(formatBalance || 0).toFixed(0);
    }

    function calculateParts() {
        return botDataById?.max_portion || 1;
    }

    const percentage = Math.min(((valueToBeMultiplied / parseNumber(calculateBalance())) * 100).toFixed(2), 100);
    const percentages = [25, 50, 75, 100];

    const setBudgetPercentage = (percentage) => {
        const budget = (parseNumber(calculateBalance()) * percentage) / 100;
        const roundedBudget = Math.round(budget);
        setValueToBeMultiplied(roundedBudget);
        setRangeValue(roundedBudget);
    };

    const startStrategie = async (e) => {
        e.preventDefault();
        setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
        const usdt = valueToBeMultiplied;
        const portion = valueToBeMultipliedParts;
        const status = true;
        const exchange = exchangeName;
        try {
            const payload = { bot_id, usdt, portion, status, exchange };
            await registerRequest(payload);
            Swal.fire({
                icon: 'success',
                iconColor: mainColors.primary,
                text: 'Registration successful',
                confirmButtonColor: mainColors.primary,
            });
            setLoadingFull({ isLoading: false });
        } catch (err) {
            Swal.fire({
                icon: 'warning',
                iconColor: '#E40039',
                confirmButtonColor: '#E40039',
                text: err.message,
            });
            setLoadingFull({ isLoading: false });
        }
        setTimeout(() => {
            history.push('/marketplace');
        }, 1000);
    };

    const updateStrategie = async (e) => {
        e.preventDefault();
        setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
        const usdt = valueToBeMultiplied;
        const portion = valueToBeMultipliedParts;
        const status = true;
        try {
            const payload = { bot_id, usdt, portion, status };
            await registerUpdateRequest(payload);
            Swal.fire({
                icon: 'success',
                iconColor: mainColors.primary,
                text: 'Update successful',
                confirmButtonColor: mainColors.primary,
            });
            setLoadingFull({ isLoading: false });
        } catch (err) {
            Swal.fire({
                icon: 'warning',
                iconColor: '#E40039',
                confirmButtonColor: '#E40039',
                text: err.message,
            });
            setLoadingFull({ isLoading: false });
        }
        setTimeout(() => {
            history.push('/general-settings/bot-management');
        }, 1000);
    };

    const unregisterBot = async () => {
        try {
            const payload = { bot_id }
            await unregisterRequest(payload);

        } catch (err) {
        } finally {
            setShowUnregisterPopup(false);
            setTimeout(() => {
                history.push('/general-settings/bot-management');
            }, 1000);
        }
    };

    return (
        <Container>
            <div className="text-fs-head-sm text-start mb-3">Bot Management</div>
            <Row className="px-0 m-5">
                <Form.Select
                    className="date-range-select"
                    value={exchangeName}
                    onChange={(e) => setExchangeName(e.target.value)}
                    aria-label="Select exchange"
                >
                    {botDataById?.exchanges?.map((exchange, index) => (
                        <option key={index} value={exchange.name}>
                            {exchange.name.charAt(0).toUpperCase() + exchange.name.slice(1).toLowerCase()}
                        </option>
                    ))}
                </Form.Select>

                <div className="shadow p-3 rounded">
                    <Row className="align-items-center mb-4">
                        <Col sm="12" md="6">
                            <div className="text-fs-head text-primary">Set the budget</div>
                        </Col>
                        <Col sm="12" md="6">
                            <InputGroup className="border-primary">
                                <Form.Control
                                    className="border-primary border-end-0"
                                    step="any"
                                    type="text"
                                    value={formatNumber(valueToBeMultiplied)}
                                    onChange={handleValueChange}
                                    aria-describedby="merx-desc"
                                />
                                <InputGroup.Text className="border-primary bg-white text-muted" id="merx-desc">
                                    $
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="mb-0">
                        <Form.Range
                            className="px-2"
                            step="any"
                            max={parseNumber(calculateBalance())}
                            min={0}
                            defaultValue={0}
                            value={rangeValue}
                            onChange={handleRangeChange}
                        />
                        <Row className="mb-0">
                            <div className="d-flex justify-content-end">
                                <div>${formatNumber(parseNumber(calculateBalance()))}</div>
                                <div className="ms-3">{percentage}%</div>
                            </div>
                        </Row>
                    </Row>
                    <Row className="m-0 justify-content-start">
                        {percentages.map((percentage, index) => (
                            <Col key={index} xs="auto">
                                <Button size="sm" className="me-2" onClick={() => setBudgetPercentage(percentage)}>
                                    {percentage}%
                                </Button>
                            </Col>
                        ))}
                    </Row>
                    <Row className="mt-5">
                        <div className="text-fs-head text-primary">How many parts do you want to divide the money into?</div>
                        <Form.Range
                            className="px-2"
                            step="any"
                            max={Number(calculateParts())}
                            min={1}
                            defaultValue={1}
                            value={valueToBeMultipliedParts}
                            onChange={(e) => setValueToBeMultipliedParts(parseInt(e.target.value))}
                        />
                        <div className="text-end">{valueToBeMultipliedParts}/{calculateParts()}</div>
                        <Button
                            className="mt-4"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (isEdit) {
                                    updateStrategie(e);
                                } else {
                                    startStrategie(e);
                                }
                            }}
                            disabled={
                                valueToBeMultiplied > parseNumber(calculateBalance()) ||
                                (!isEdit &&
                                    botDataById?.exchanges?.some(exchange =>
                                        exchange.name === registeredBotData?.bots?.[0]?.exchange &&
                                        botDataById?.id === registeredBotData?.bots?.[0]?.bot_id
                                    )
                                )
                            }
                        >
                            {
                                isEdit
                                    ? "Update"
                                    : (botDataById?.exchanges?.some(exchange =>
                                        exchange.name === registeredBotData?.bots?.[0]?.exchange &&
                                        botDataById?.id === registeredBotData?.bots?.[0]?.bot_id
                                    )
                                        ? "Registered"
                                        : "Start"
                                    )
                            }
                        </Button>

                        {isEdit && (
                            <Button
                                className="mt-4"
                                variant="danger"
                                onClick={() => setShowUnregisterPopup(true)}
                            >
                                Unregister
                            </Button>
                        )}
                    </Row>
                </div>
            </Row>
            <Modal
                show={showUnregisterPopup}
                onHide={() => setShowUnregisterPopup(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You are about to unregister from this bot. Please note that all your open trades will be canceled. Do you wish to proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowUnregisterPopup(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={unregisterBot}
                    >
                        Unregister
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    botDataById: state.botReducer.botDataById,
    registeredBotData: state.botReducer.registeredBotData,
});

const mapDispatchToProps = (dispatch) => ({
    buyTokenModalRequest: (payload) => dispatch(buyTokenModalAction(payload)),
    setLoading: (payload) => dispatch(setLoadingAction(payload)),
    setLoadingFull: (payload) => dispatch(setLoadingFullAction(payload)),
    registerRequest: (creds) => dispatch(registerRequest(creds)),
    registerUpdateRequest: (creds) => dispatch(registerUpdateRequest(creds)),
    unregisterRequest: (creds) => dispatch(unregisterRequest(creds)),
    getBotByIdRequest: (payload) => dispatch(getBotByIdRequestAction(payload)),
    getRegisteredBotRequest: (payload) => {
        dispatch(getRegisteredBotRequestAction(payload));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BotManagementDetailInputs);
