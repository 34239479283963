import * as types from './botActionTypes';

export const getBotRequestAction = (payload) => {
  return {
    type: types.GET_BOT_REQUEST,
    payload,
  };
};

export const getBotDataAction = (payload) => {
  return {
    type: types.GET_BOT_DATA,
    payload,
  };
};

export const getBotErrorAction = (payload) => {
  return {
    type: types.GET_BOT_ERROR,
    payload,
  };
};

export const getBotByIdRequestAction = (payload) => {
  return {
    type: types.GET_BOT_BY_ID_REQUEST,
    payload,
  };
};

export const getBotByIdDataAction = (payload) => {
  return {
    type: types.GET_BOT_BY_ID_DATA,
    payload,
  };
};

export const getBotByIdErrorAction = (payload) => {
  return {
    type: types.GET_BOT_BY_ID_ERROR,
    payload,
  };
};
export const getRegisteredBotRequestAction = (payload) => {
  return {
    type: types.GET_REGISTERED_BOT_REQUEST,
    payload,
  };
};

export const getRegisteredBotDataAction = (payload) => {
  return {
    type: types.GET_REGISTERED_BOT_DATA,
    payload,
  };
};

export const getRegisteredBotErrorAction = (payload) => {
  return {
    type: types.GET_REGISTERED_BOT_ERROR,
    payload,
  };
};
/**
 *
 
 */
export const registerRequest = (creds) => {
  
  return {
    
    type: types.REGISTER_REQUEST,
    creds,
  };
};
/**
 *
 
 */
export const registerData = (payload) => {
  return {
    type: types.REGISTER_DATA,
    payload,
  };
};
export const registerError = (payload) => {
  return {
    type: types.REGISTER_ERROR,
    payload,
  };
};

export const getBotHistoryRequestAction = (payload) => {
  return {
    type: types.GET_BOT_HISTORY_REQUEST,
    payload,
  };
};

export const getBotHistoryDataAction = (payload) => {
  return {
    type: types.GET_BOT_HISTORY_DATA,
    payload,
  };
};

export const getBotHistoryErrorAction = (payload) => {
  return {
    type: types.GET_BOT_HISTORY_ERROR,
    payload,
  };
};

export const getCustomBotHistoryRequestAction = (payload) => {
  return {
    type: types.GET_CUSTOM_BOT_HISTORY_REQUEST,
    payload,
  };
};

export const getCustomBotHistoryDataAction = (payload) => {
  return {
    type: types.GET_CUSTOM_BOT_HISTORY_DATA,
    payload,
  };
};

export const getCustomBotHistoryErrorAction = (payload) => {
  return {
    type: types.GET_CUSTOM_BOT_HISTORY_ERROR,
    payload,
  };
};

export const sortBotHistorySortData = (payload) => {
  return {
    type: types.SET_BOT_HISTORY_SORT_DATA,
    payload,
  };
};
export const sortingBotHistoryAction = (payload) => {
  return {
    type: types.SORTING_BOT_HISTORY,
    payload,
  };
};
export const setBotHistorySortDataAction = (payload) => {
  return {
    type: types.SET_BOT_HISTORY_SORT_DATA,
    payload,
  };
};
export const registerUpdateRequest = (creds) => {
  
  return {
    
    type: types.REGISTER_UPDATE_REQUEST,
    creds,
  };
};
/**
 *
 
 */
export const registerUpdateData = (payload) => {
  return {
    type: types.REGISTER_UPDATE_DATA,
    payload,
  };
};
export const registerUpdateError = (payload) => {
  return {
    type: types.REGISTER_UPDATE_ERROR,
    payload,
  };
};
export const unregisterRequest = (creds) => {
  return {
    type: types.UNREGISTER_REQUEST,
    creds,
  };
};
/**
 *
 
 */
export const unregisterData = (payload) => {
  return {
    type: types.UNREGISTER_DATA,
    payload,
  };
};
export const unregisterError = (payload) => {
  return {
    type: types.UNREGISTER_ERROR,
    payload,
  };
};
export const getMyBotHistoryRequestAction = (payload) => {
  return {
    type: types.GET_MY_BOT_HISTORY_REQUEST,
    payload,
  };
};

export const getMyBotHistoryDataAction = (payload) => {
  return {
    type: types.GET_MY_BOT_HISTORY_DATA,
    payload,
  };
};

export const getMyBotHistoryErrorAction = (payload) => {
  return {
    type: types.GET_MY_BOT_HISTORY_ERROR,
    payload,
  };
};
export const sortMyBotHistorySortData = (payload) => {
  return {
    type: types.SET_MY_BOT_HISTORY_SORT_DATA,
    payload,
  };
};
export const sortingMyBotHistoryAction = (payload) => {
  return {
    type: types.SORTING_MY_BOT_HISTORY,
    payload,
  };
};

export const getPnlHistoryRequestAction = (payload) => {
  return {
    type: types.GET_PNL_HISTORY_REQUEST,
    payload,
  };
};

export const getPnlHistoryDataAction = (payload) => {
  return {
    type: types.GET_PNL_HISTORY_DATA,
    payload,
  };
};

export const getPnlHistoryErrorAction = (payload) => {
  return {
    type: types.GET_PNL_HISTORY_ERROR,
    payload,
  };
};