import {
  BOT_HISTORY_KEYS,
  BOT_HISTORY_SORT_TYPES,
  sortKeys,
} from '../../pages/BotDetail/botDetailConstants';
import {
  MY_BOT_HISTORY_KEYS,
  MY_BOT_HISTORY_SORT_TYPES,
} from '../../pages/MyBotHistory/myBotHistoryConstants';
import * as types from './botActionTypes';

const initialState = {
  successStartStart: null,
  successUpdateStart: null,
  botData: null,
  botDataById: null,
  registeredBotData: null,
  filteredBotHistory: null,
  botHistoryData: null,
  botHistory: null,
  botHistorySortData: {
    sortType: BOT_HISTORY_SORT_TYPES.DESCENDING,
    sortKey: BOT_HISTORY_KEYS.DATE,
  },
  myBotHistoryData: null,
  myBotHistorySortData: {
    sortType: MY_BOT_HISTORY_SORT_TYPES.ASCENDING,
    sortKey: MY_BOT_HISTORY_KEYS.DATE,
  },
  error: {
    type: null,
    data: null,
  },
  pnlHistoryData: null,
};

const sortBySalesTimestamp = (data, sortType, sortKey = null) => {
  const bots = data[0] || [];
  console.log(sortKey)
  console.log(sortType)
  return bots.sort((a, b) => {
    let valueA, valueB;

    if (sortKey === 1) {
      valueA = parseFloat(a.profit_loss) || 0;
      valueB = parseFloat(b.profit_loss) || 0;
    } else if (sortKey === 2) {
      valueA = parseFloat(a.profit_loss_percentage) || 0;
      valueB = parseFloat(b.profit_loss_percentage) || 0;
    } else {
      valueA = new Date(a.sales_timestamp);
      valueB = new Date(b.sales_timestamp);
    }

    return sortType === BOT_HISTORY_SORT_TYPES.ASCENDING
      ? valueA - valueB
      : valueB - valueA;
  });
};


export const botReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BOT_DATA:
      return {
        ...state,
        botData: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_BOT_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.GET_BOT_BY_ID_DATA:
      return {
        ...state,
        botDataById: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_BOT_BY_ID_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.GET_REGISTERED_BOT_DATA:
      return {
        ...state,
        registeredBotData: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_REGISTERED_BOT_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.REGISTER_DATA:
      return {
        ...state,
        successStart: action.successStart ? Object.assign({}, action.successStart) : null,
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        error: {
          type: types.REGISTER_ERROR,
          data: action.payload,
        },
      };
    case types.REGISTER_UPDATE_DATA:
      return {
        ...state,
        successUpdate: action.successUpdate ? Object.assign({}, action.successUpdate) : null,
      };
    case types.REGISTER_UPDATE_ERROR:
      return {
        ...state,
        error: {
          type: types.REGISTER_ERROR,
          data: action.payload,
        },
      };
    case types.GET_BOT_HISTORY_DATA:
      return {
        ...state,
        botHistoryData: action?.payload ?
          sortBySalesTimestamp([...action?.payload],
            state.botHistorySortData.sortType,
            state.botHistorySortData.sortKey) : null,
      };
    case types.GET_CUSTOM_BOT_HISTORY_DATA:
      return {
        ...state,
        botHistoryData: action?.payload ?
          sortBySalesTimestamp([...action?.payload], 
          state.botHistorySortData.sortType,
          state.botHistorySortData.sortKey) : null,
      };
    case types.SET_BOT_HISTORY_SORT_DATA:
      return {
        ...state,
        botHistorySortData: { ...action.payload },
      };
    case types.SORT_BOT_HISTORY:
      const selectedKey = sortKeys[state.botHistorySortData.sortKey].key;
      const tempBotHistoryForSorting = state.filteredBotHistory?.length
        ? state.filteredBotHistory
        : state.botHistory;

      const sortedBotHistory = tempBotHistoryForSorting?.sort((a, b) => {
        if (state.botHistorySortData.sortType === BOT_HISTORY_SORT_TYPES.ASCENDING) {
          return (
            a.token[selectedKey]
              ?.toString()
              .toLowerCase()
              .localeCompare(b.token[selectedKey]?.toString().toLowerCase())
          );
        } else {
          return (
            b.token[selectedKey]
              ?.toString()
              .toLowerCase()
              .localeCompare(a.token[selectedKey]?.toString().toLowerCase())
          );
        }
      });
      return {
        ...state,
        botHistory: sortedBotHistory ? [...sortedBotHistory] : state.botHistory,
      };
    case types.GET_BOT_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_HISTORY_ERROR,
          data: action.payload,
        },
      };
    case types.GET_MY_BOT_HISTORY_DATA:
      return {
        ...state,
        myBotHistoryData: action?.payload
          ? sortBySalesTimestamp(
            [...action?.payload],
            state.myBotHistorySortData.sortType,
            state.myBotHistorySortData.sortKey
          )
          : null,
      };
    case types.SET_MY_BOT_HISTORY_SORT_DATA:
      return {
        ...state,
        myBotHistorySortData: { ...action.payload },
      };
    case types.GET_MY_BOT_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_MY_BOT_HISTORY_ERROR,
          data: action.payload,
        },
      };
    case types.GET_PNL_HISTORY_DATA:
      return {
        ...state,
        pnlHistoryData: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_PNL_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_PNL_HISTORY_ERROR,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
