import * as types from './newsActionTypes';

const initialState = {
  newsData: null,
  error: {
    type: null,
    data: null,
  },
};

export const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NEWS_DATA:
      return {
        ...state,
        newsData: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_NEWS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_NEWS_ERROR,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
