/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, FormLabel } from 'react-bootstrap';
import { resetPasswordRequest } from '../../store/account/userActions';
import { setAlertAction } from '../../store/alert/alertActions';
import './ResetPassword.scss';
import { NavLink } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

function ResetPassword({ ...props }) {
  const { history, resetpassword, setAlert } = props;
  const [state, setState] = useState({
    data: {
      password: '',
      confirmPassword: '',
      resetToken: params.token,
    },
    errors: {},
  });

  const validatePassword = (password) => {
    const errors = [];

    if (!password || password.length < 8) {
      errors.push({ message: 'Must be at least 8 characters long.', isValid: false });
    } else {
      errors.push({ message: 'Must be at least 8 characters long.', isValid: true });
    }

    if (!/[A-Z]/.test(password)) {
      errors.push({ message: 'Must contain at least one uppercase letter.', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one uppercase letter.', isValid: true });
    }

    if (!/[a-z]/.test(password)) {
      errors.push({ message: 'Must contain at least one lowercase letter.', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one lowercase letter.', isValid: true });
    }

    if (!/\d/.test(password)) {
      errors.push({ message: 'Must contain at least one digit.', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one digit.', isValid: true });
    }

    if (!/[@$!%*?&]/.test(password)) {
      errors.push({ message: 'Must contain at least one special character (@, $, !, %, *, ?, &).', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one special character (@, $, !, %, *, ?, &).', isValid: true });
    }

    return errors;
  };

  const validate = () => {
    const { data } = state;
    const errors = {};

    if (!data || typeof data !== 'object') {
      // Handle the case where data is null or not an object
      return errors;
    }

    if (!data.password) {
      errors.password = 'Password cannot be blank.';
    } else {
      const passwordErrors = validatePassword(data.password);
      const invalidPasswordErrors = passwordErrors.filter(error => !error.isValid);

      if (invalidPasswordErrors.length > 0) {
        errors.password = invalidPasswordErrors.map(error => error.message).join(' ');
      }
    }

    if (!data.confirmPassword) {
      errors.confirmPassword = 'Confirm Password cannot be blank.';
    } else if (data.password !== data.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match!';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { data } = state;
    const errors = validate();

    const payload = {
      password: data.password,
      resetToken: data.resetToken,
      history: history,
    };

    if (!errors.password && !errors.confirmPassword) {
      resetpassword(payload);

      setState({
        data: {
          password: '',
          confirmPassword: '',
          resetToken: params.token,
        },
        errors: {},
      });
    } else {
      const alertText = Object.values(errors);
      setAlert({
        title: 'Warning!',
        text: alertText,
        variant: 'warning',
        outTimeMS: 3000,
      });
      setState({
        data: {
          password: '',
          confirmPassword: '',
          resetToken: params.token,
        },
        errors,
      });
    }
  };

  const handleChange = (e, targetID) => {
    setState({
      data: {
        ...state.data,
        [targetID]: e.target.value,
      },
      errors: {
        ...state.errors,
        [targetID]: '',
      },
    });
  };
  const handleTogglePasswordVisibility = () => {
    setState(prevState => ({
      ...prevState,
      passwordVisibility: !prevState.passwordVisibility,
    }));
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setState(prevState => ({
      ...prevState,
      confirmPasswordVisibility: !prevState.confirmPasswordVisibility,
    }));
  };
  const { data, errors, passwordVisibility, confirmPasswordVisibility } = state;

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col className="public-resetpw-layout-col d-flex justify-content-center align-items-center bg-white px-1 py-2">
          <div className="public-resetpw-layout-image d-md-flex d-none flex-column justify-content-center align-items-center bg-primary px-1 py-2">
            <div className="text-fs-head-lg text-center text-light px-2">
              Welcome to the exciting world of <span>MultiSignals</span>
            </div>
            <div className="text-fs-body-md text-center text-light px-2">
              {/* Step into the dynamic sphere of MercurX, where each exploration promises fascinating
              discoveries in the world of digital currency and blockchain! */}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center aling-items-center px-4 mx-2 public-resetpw-layout-form">
            <Form onSubmit={handleSubmit}>
              <FormLabel className='text-fs-body-lg text-t-head-color'>
                Password
              </FormLabel>
              <InputGroup>
                <Form.Control
                  type={passwordVisibility ? 'text' : 'password'}
                  name="password"
                  id="password"
                  value={data.password}
                  isInvalid={!!errors.password}
                  onChange={(e) => {
                    handleChange(e, 'password');
                  }}
                />
                <InputGroup.Text id="basic-addon2" className='d-flex justify-content-between align-items-center input-group-text-custom'>
                  <Col >
                    <div
                      onClick={handleTogglePasswordVisibility}
                    >
                      <MercurXIcons
                        name={passwordVisibility ? 'BsEyeSlash' : 'BsEye'}
                        iconClassName="mercurx-password-icon"
                      />
                    </div>
                  </Col>

                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </InputGroup>
              <FormLabel className='text-fs-body-lg text-t-head-color'>
                Confirm Password
              </FormLabel>
              <InputGroup>
                <Form.Control
                  type={confirmPasswordVisibility ? 'text' : 'password'}
                  name="confirmPassword"
                  id="confirmPassword"
                  value={data.confirmPassword}
                  isInvalid={!!errors.confirmPassword}
                  onChange={(e) => {
                    handleChange(e, 'confirmPassword');
                  }}
                />
                <InputGroup.Text id="basic-addon2" className='d-flex justify-content-center align-items-center input-group-text-custom'>
                  <Col >
                    <div
                      onClick={handleToggleConfirmPasswordVisibility}
                    >
                      <MercurXIcons
                        name={confirmPasswordVisibility ? 'BsEyeSlash' : 'BsEye'}
                        iconClassName="mercurx-password-icon"
                      />
                    </div>
                  </Col>

                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
              </InputGroup>
              {data?.password && validatePassword(data.password).map((error, index) => (
                <Form.Label key={index} className={`text-${error.isValid ? 'success' : 'danger'} text-fs-body-sm mercurx-error-label`}>
                  {error.isValid ? '✓ ' : '* '}
                  {error.message}
                </Form.Label>
              ))}
              <Button className="mb-4 mt-4 bg-mercurx-primary col-sm-12" type="submit">
                Reset Password
              </Button>
            </Form>

            <Col className="m-1 d-flex justify-content-center">
              <NavLink className="text-fs-body-md text-t-body-color" to="/signup">
                Don't have an account yet?
              </NavLink>
              &nbsp;
              <NavLink className="signup-link text-primary" to="/signup">
                SignUp
              </NavLink>
            </Col>
          </div>
        </Col>
      </Row>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetpassword: (creds) => {
      dispatch(resetPasswordRequest(creds));
    },
    setAlert: (payload) => {
      dispatch(setAlertAction(payload));
    },
  };
};

export default connect(null, mapDispatchToProps)(ResetPassword);
