const BOT_HISTORY_SORT_TYPES = {
  ASCENDING: 0,
  DESCENDING: 1,
};

const BOT_HISTORY_KEYS = {
  DATE: 0,
  PROFIT_LOSS: 1,
  PROFIT_LOSS_PERCENTAGE: 2,
};

const sortTypes = [
  {
    name: 'Ascending',
    icon: 'BiSortUp',
  },
  {
    name: 'Descending',
    icon: 'BiSortDown',
  },
];

const sortKeys = [
  { name: 'Sell Timestamp', key: 'date' },
  { name: 'Profit Loss $', key: 'profit_loss' },
  { name: 'Profit Loss %', key: 'profit_loss_percentage' },
];

export { BOT_HISTORY_SORT_TYPES, BOT_HISTORY_KEYS, sortTypes, sortKeys };
