export const GET_BOT_REQUEST = 'GET_BOT_REQUEST';
export const GET_BOT_DATA = 'GET_BOT_DATA';
export const GET_BOT_ERROR = 'GET_BOT_ERROR';

export const GET_BOT_BY_ID_REQUEST = 'GET_BOT_BY_ID_REQUEST';
export const GET_BOT_BY_ID_DATA = 'GET_BOT_BY_ID_DATA';
export const GET_BOT_BY_ID_ERROR = 'GET_BOT_BY_ID_ERROR';

export const GET_REGISTERED_BOT_REQUEST = 'GET_REGISTERED_BOT_REQUEST';
export const GET_REGISTERED_BOT_DATA = 'GET_REGISTERED_BOT_DATA';
export const GET_REGISTERED_BOT_ERROR = 'GET_REGISTERED_BOT_ERROR';


export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_DATA = 'REGISTER_DATA';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const REGISTER_UPDATE_REQUEST = 'REGISTER_UPDATE_REQUEST';
export const REGISTER_UPDATE_DATA = 'REGISTER_UPDATE_DATA';
export const REGISTER_UPDATE_ERROR = 'REGISTER_UPDATE_ERROR';

export const UNREGISTER_REQUEST = 'UNREGISTER_REQUEST';
export const UNREGISTER_DATA = 'UNREGISTER_DATA';
export const UNREGISTER_ERROR = 'UNREGISTER_ERROR';

export const GET_BOT_HISTORY_REQUEST = 'GET_BOT_HISTORY_REQUEST';
export const GET_BOT_HISTORY_DATA = 'GET_BOT_HISTORY_DATA';
export const GET_BOT_HISTORY_ERROR = 'GET_BOT_HISTORY_ERROR';

export const SET_BOT_HISTORY_SORT_DATA = 'SET_TOKEN_SORT_DATA';
export const SORTING_BOT_HISTORY = 'SORTING_BOT_HISTORY';
export const SORT_BOT_HISTORY = 'SORT_BOT_HISTORY';

export const GET_MY_BOT_HISTORY_REQUEST = 'GET_MY_BOT_HISTORY_REQUEST';
export const GET_MY_BOT_HISTORY_DATA = 'GET_MY_BOT_HISTORY_DATA';
export const GET_MY_BOT_HISTORY_ERROR = 'GET_MY_BOT_HISTORY_ERROR';

export const SET_MY_BOT_HISTORY_SORT_DATA = 'SET_MY_BOT_HISTORY_SORT_DATA';
export const SORTING_MY_BOT_HISTORY = 'SORTING_MY_BOT_HISTORY';

export const GET_PNL_HISTORY_REQUEST = 'GET_PNL_HISTORY_REQUEST';
export const GET_PNL_HISTORY_DATA = 'GET_PNL_HISTORY_DATA';
export const GET_PNL_HISTORY_ERROR = 'GET_PNL_HISTORY_ERROR';

export const GET_CUSTOM_BOT_HISTORY_REQUEST = 'GET_CUSTOM_BOT_HISTORY_REQUEST';
export const GET_CUSTOM_BOT_HISTORY_DATA = 'GET_CUSTOM_BOT_HISTORY_DATA';
export const GET_CUSTOM_BOT_HISTORY_ERROR = 'GET_CUSTOM_BOT_HISTORY_ERROR';
