/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import './UserHome.scss';
import { Container, Row, Col, Card, Button, Nav, Tab, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { accountDetailsRequestAction } from '../../store/account/userActions';
import { useHistory } from 'react-router-dom';
import multisignalsLogo from '../../assets/img/logo/logo.png';
import btcusdt from '../../assets/img/coin/btcusdt.png';
import ethusdt from '../../assets/img/coin/ethusdt.png';
import bnbusdt from '../../assets/img/coin/bnbusdt.png';
import xrpusdt from '../../assets/img/coin/xrpusdt.png';
import solusdt from '../../assets/img/coin/solusdt.png';
import xml2js from 'xml2js';
import PnlHistoryChart from '../../components/PnlHistoryChart/PnlHistoryChart';
import {
  getPnlHistoryRequestAction,
} from '../../store/bot/botActions';
import{
  getNewsRequestAction
} from '../../store/news/newsActions'

function UserHome({ ...props }) {
  const { user, accountDetailsRequest, getPnlHistoryRequestAction, getNewsRequest,newsData } = props;
  const history = useHistory();

  const [popularTokens, setPopularTokens] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {

    getPnlHistoryRequestAction("1_day");
  }, []);

  useEffect(() => {
    accountDetailsRequest();

    const coins = ['btcusdt', 'ethusdt', 'bnbusdt', 'xrpusdt', 'solusdt'];

    const ws = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${coins.map(coin => `${coin}@ticker`).join('/')}`);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.data) {
        const coinSymbol = data?.data.s.toLowerCase();
        const coinPrice = data.data.c;
        const coinQuantity = data.data.P;

        setPopularTokens((prevTokens) => {
          const updatedTokens = [...prevTokens];
          const coinIndex = coins.indexOf(coinSymbol);
          updatedTokens[coinIndex] = {
            symbol: coinSymbol.toUpperCase(),
            price: coinPrice,
            quantity: coinQuantity
          };
          return updatedTokens;
        });
      }
    };

    return () => {
      ws.close();
    };
  }, [accountDetailsRequest]);

  useEffect(() => {
    accountDetailsRequest();
  }, [accountDetailsRequest]);

  useEffect(() => {
    getNewsRequest();
  }, [getNewsRequest]);

  useEffect(() => {
    const fetchNews = () => {
      try {
        const rawData = newsData;
    
        const rssString = typeof rawData === 'object' ? Object.values(rawData).join('') : rawData;
    
    
        const parser = new xml2js.Parser();
        parser.parseString(rssString, (err, result) => {
          if (err) {
            return;
          }
          console.log(result.rss.channel);
          const latestNews = result.rss.channel[0].item.slice(0, 5);
          setNews(latestNews);
        });
      } catch (error) {
      }
    };
    

    fetchNews();
  }, [newsData]);

  const redirectApiKey = () => {
    history.push('/general-settings/api-settings');
  };

  const getTokenImage = (symbol) => {
    switch (symbol?.toLowerCase()) {
      case 'btcusdt':
        return btcusdt;
      case 'ethusdt':
        return ethusdt;
      case 'bnbusdt':
        return bnbusdt;
      case 'xrpusdt':
        return xrpusdt;
      case 'solusdt':
        return solusdt;
      default:
        return null;
    }
  };

  return (
    <div className="home-page">
      <Container fluid className="text-center ">
        <img src={multisignalsLogo} alt="Logo" width={100} />
        <h2 className="section-title">Welcome to Multisignals</h2>
        <div className="user-greeting">
          <h3 className='text-primary'>{user?.full_name || 'Guest'}</h3>
          <h6>{user?.email || 'Guest'}</h6>
        </div>

        <Row className="mt-4 mx-0">
          <Col sm={12}>
            <Card className="custom-shadow">
              <Card.Body>
                <Card.Title className="text-primary">Account Overview</Card.Title>
                <Card.Text className="text-light p-1 card-text">
                  <Row>
                    <Col sm={4}>
                      <strong>API Key Status: </strong>
                      {user?.api_key_status ? 'Active' :
                        <Button className="btn btn-primary" onClick={redirectApiKey}>Add API Key</Button>}
                    </Col>
                    <Col sm={4}>
                      <strong>USDT Balance: </strong>
                      {user?.usdt_balance?.free ? `$${Number(user?.usdt_balance.free).toFixed(3).slice(0, -1) }` :
                        <Button className="btn btn-primary" onClick={redirectApiKey}>Add API Key</Button>}
                    </Col>
                    <Col sm={4}>
                      <strong>Registered: </strong>
                      {user?.registered_bots ||
                        <Button className="btn btn-primary" onClick={redirectApiKey}>Add API Key</Button>}
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Container fluid className="mt-4">
          <h2 className="section-title">Cryptocurrency Market</h2>
          <Tab.Container id="market-tabs" defaultActiveKey="popular">
            <Row>
              <Col sm={12} md={6} className="mb-4">
                <Nav variant="tabs" className="d-flex justify-content-center">

                  <strong className='p-1'>Popular Tokens</strong>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="popular">
                    <div className="token-card">
                      <Table className="fixed-table table-borderless mt-2" striped responsive>
                        <tbody>
                          {popularTokens.map((token, index) => (
                            <tr key={index}>
                              <td className="symbol-column">
                                <img
                                  src={getTokenImage(token?.symbol)}
                                  alt={token?.symbol}
                                  width={30}
                                  height={30}
                                  style={{ marginRight: 12 }}
                                />
                                {token?.symbol === 'BTCUSDT' ? 'BTC' :
                                  token?.symbol === 'ETHUSDT' ? 'ETH' :
                                    token?.symbol === 'BNBUSDT' ? 'BNB' :
                                      token?.symbol === 'XRPUSDT' ? 'XRP' :
                                        token?.symbol === 'SOLUSDT' ? 'SOL' : token?.symbol}
                              </td>
                              <td className="price-column">$
                                {new Intl.NumberFormat('en-US').format(parseFloat(token?.price))}
                              </td>
                              <td
                                className="quantity-column"
                                style={{
                                  color: parseFloat(token?.quantity) > 0 ? 'green' : '#f40a0a',
                                }}
                              >
                                {parseFloat(token?.quantity) > 0 ? `+${parseFloat(token?.quantity).toFixed(2)}%` : `${parseFloat(token?.quantity).toFixed(2)}%`}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>

              <Col sm={12} md={6} className="mb-4">
                <Nav variant="tabs" className="d-flex justify-content-center">
                  <strong className='p-1'>News</strong>
                </Nav>
                <div className="news-card">
                  {news?.length > 0 ? (
                    <ul >
                      {news?.map((article, index) => (
                        <li key={index} >
                          <a
                            href={article.link[0]}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {article.title[0]}
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>Loading news...</p>
                  )}
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
        {user?.api_key_status ? (
          <div className=''>
            <PnlHistoryChart />
          </div>
        ) : null}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    botHistoryData: state.botReducer.botHistoryData,
    botStatisticsData: state.botReducer.botStatisticsData,
    botHistorySortData: state.botReducer.botHistorySortData,
    isLoading: state.loadingReducer.isLoading,
    user: state.userReducer.user,
    newsData: state.newsReducer.newsData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    accountDetailsRequest: (payload) => {
      dispatch(accountDetailsRequestAction(payload));
    },
    getPnlHistoryRequestAction: (creds) => {
      dispatch(getPnlHistoryRequestAction(creds));
    },
    getNewsRequest: (payload) => {
      dispatch(getNewsRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserHome);
