/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import './AddNewApiModal.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import {
  apiKeyRequest
} from '../../store/apiKey/apiKeyActions';
function AddNewApi({handleClose, ...props }) {
  const {
    apiKeyRequest,
  } = props;
  const [txs, setTxs] = useState([]);

  const apiKeyRegex = /^[a-zA-Z0-9]{64}$/;
  const apiSecretRegex = /^[a-zA-Z0-9!@#$%^&*()_+[\]{};':"\\|,.<>\/?`~\-]{32,64}$/;

  const [errors, setErrors] = useState({ apiKey: '', apiSecret: '' });
  const validateInputs = () => {
    const newErrors = { apiKey: '', apiSecret: '' };
    if (!apiKeyRegex.test(apiKey)) {
      newErrors.apiKey = 'API Key must be 16-32 alphanumeric characters.';
    }
    if (!apiSecretRegex.test(apiSecret)) {
      newErrors.apiSecret = 'API Secret must be 32-64 characters, including special characters.';
    }
    setErrors(newErrors);
    return !newErrors.apiKey && !newErrors.apiSecret;
  };

  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const addApiKey = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;
    try {
      const api_key = apiKey;
      const api_secret = apiSecret;
      const payload = {
        api_key,
        api_secret,
      };
      console.log(payload)
      await apiKeyRequest(payload);
      handleClose();
      // if (response && response.success) { // Başarı durumunu response.success ile kontrol edin
      //   handleClose(); // İşlem başarılıysa modalı kapat
      // }
    } catch (err) {
    }
  };
  return (
    <>
      <form className="m-0"
      // onSubmit={buyToken}
      >
        <div
        // className="credit-card w-full 
        // lg:w-3/4 sm:w-auto 
        // shadow-lg mx-auto rounded-xl"
        >
          <main className="px-4">
            <div className="mx-3">
              <div className="my-3">
                <div className="input-group">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder='API Key'
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                      isInvalid={!!errors.apiKey}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.apiKey}
                    </Form.Control.Feedback>
                  </InputGroup>
                </div>
              </div>
              <div className="my-3">
                <div className="input-group">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder='API Secret'
                      value={apiSecret}
                      onChange={(e) => setApiSecret(e.target.value)}
                      isInvalid={!!errors.apiSecret}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.apiSecret}
                    </Form.Control.Feedback>
                  </InputGroup>
                </div>
              </div>
              <div className='mb-4'>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    addApiKey(e);
                  }}>
                  Save
                </Button>
              </div>
            </div>
          </main>

        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiKeyRequest: (creds) => {
      dispatch(apiKeyRequest(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewApi);
